
import { defineComponent, onMounted, ref, computed} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import AccountBase from "@/views/crafted/account/Account-Base.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { useI18n } from 'vue-i18n';


interface ProfileDetails {
  avatar: string;
  name: string;
  bio: string;
  nick: string;
  country: string;
  language: string;
  github_url: string;
  linkedin_url: string;
}

export default defineComponent({
  name: "Account-Settings",
  data() {
    return {
      nick_is_available: "",
      nick_is_available_status: "",
      loading: false,
    }
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    AccountBase
    },
  setup() {
     const store = useStore();
     const { t } = useI18n();
     const user = computed<ProfileDetails>(() => {return store.getters.currentUser});
     const profileDetails = computed<ProfileDetails>(() => {return store.getters.currentUser});
     const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));




    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required(t(`${currentLanguage.value}.profile.name_is_a_required_field`)).label("Full Name"),
      nick: Yup.string().required(t(`${currentLanguage.value}.profile.nick_is_a_required_field`)).label("Nick"),
      bio: Yup.string().nullable().label("Bio"),
      github_url: Yup.string().nullable().label("GitHub"),
      linkedin_url: Yup.string().nullable().label("Linkedin"),
      country: Yup.string().required(t(`${currentLanguage.value}.profile.country_is_a_required_field`)).label("Country")
    });

    

   
  
    const saveChanges =  async () => {
    
      if (submitButton1.value) {
        // validate if form is valid
        if(typeof profileDetails.value.linkedin_url === "undefined"){
          profileDetails.value.linkedin_url = "";
        }
        if(typeof profileDetails.value.github_url === "undefined"){
          profileDetails.value.github_url = "";
        }
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

          ApiService.vueInstance.axios.put("account/profile", profileDetails.value)
          .then(({ data }) => {

            if(data.success) {
                Swal.fire(
                t(`${currentLanguage.value}.common.success`),
                t(`${currentLanguage.value}.profile.your_account_has_been_updated_successfully`),
                'success'
              )
            }else{
              Swal.fire(
                'Oops!',
                  data.message,
                'error'
              )
            }
           
            
            submitButton1.value?.removeAttribute("data-kt-indicator");

          })
          .catch(({ response }) => {
             Swal.fire(
                'Opps!',
                response.data.message,
                'error'
              )
            store.commit(Mutations.SET_ERROR, response.data.errors);
            submitButton1.value?.removeAttribute("data-kt-indicator");
          });

      
      }
    };

  

 
    const deactivateAccount = () => {
      if (submitButton5.value) {
        // Activate indicator
        submitButton5.value.setAttribute("data-kt-indicator", "on");
        submitButton5.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            title: t(`${currentLanguage.value}.profile.are_you_sure`),
            text: "Again, this action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, destroy my account!'
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Deleted!',
                'Your account has been deleted from Hacking Club!',
                'success'
              )
              store.commit(Mutations.PURGE_AUTH);
              window.location.href = "/";
            }
          })
         
   
      }
    };

  

  
    const removeImage = () => {
      profileDetails.value.avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {
      submitButton1,
      submitButton5,
      saveChanges,
      deactivateAccount,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      removeImage,
      profileDetailsValidator,
      user,
      store,
      currentLanguage
    };
  },
  methods: {
    async checkNick(nick: string) {
      if(nick.length < 3) {
        this.nick_is_available_status = "text-danger";
        this.nick_is_available = this.$t(`${this.currentLanguage}.profile.nick_must_least_3_characters_long`);
        return;
      }
      await ApiService.vueInstance.axios.post(`account/profile/checknick`, {
        "nick": nick
      }).then(({ data }) => {
        if(data.success) {
          this.nick_is_available = this.$t(`${this.currentLanguage}.profile.nick_available`);
          this.nick_is_available_status = "text-success";
        }else{
          this.nick_is_available_status = "text-danger";
          this.nick_is_available = this.$t(`${this.currentLanguage}.profile.this_nick_is_already_taken`);
        }
      })
    },
    async changeAvatar(){
      // send file to server on route /account/profile/avatar
      this.loading = true;
      // get file from input
      const file = document.getElementById("file_input_avatar") as HTMLInputElement;
      const formData = new FormData();
      if(file.files){
        formData.append("image", file.files[0]);
      }
      
      await ApiService.vueInstance.axios.post(`account/profile/avatar`, formData)
      .then(({ data }) => {
       // get status code 
      this.loading = false;
      this.store.dispatch(Actions.VERIFY_AUTH);

       Swal.fire(
           this.$t(`${this.currentLanguage}.common.success`),
           this.$t(`${this.currentLanguage}.profile.your_image_has_been_updated_successfully`),
            'success'
          )
       
      }).catch(({ response }) => {
        this.loading = false;
        Swal.fire(
            'Opps!',
            'Error while updating your profile image!',
            'error'
          )
      })
      this.loading = false;
    }
  }
});

